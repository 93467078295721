import React from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import downloadSrc from '../../images/svg/download.svg';
import mailSrc from '../../images/svg/mail.svg';

const Wrapper = styled.div`
  margin: 0 auto;
  order: 2;

  @media(min-width: 1200px) {
    order: 1;
  }
`;

const Title = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary};
  margin-bottom: 1rem;
  grid-column: span 3;

  @media(min-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const SecondTitle = styled(Title)`
  margin-bottom: 0;
`;

const ContentSpacer = styled.div`
  margin-top: 2rem;

  @media(min-width: 992px) {
    margin-top: 3rem;
  }

  @media(min-width: 1200px) {
    margin-top: 6rem;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr max-content;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  line-height: 1.125rem;

  @media(min-width: 768px) {
    grid-template-columns: 50px minmax(150px, 1fr) max-content;
  }
  @media(min-width: 1200px) {
    min-width: 400px;
  }

`;

const Divider = styled.div`
  grid-column: span 3;
  border: 1px solid ${({theme}) => theme.colors.primary};
`;

const Spacer = styled.div`
  grid-column: span 3;
  padding-bottom: 1rem;
`;


const Number = styled.span`
  color: ${({theme}) => theme.colors.primary};
  font-size: 1.125rem;
  font-weight: 700;
`;

const Value = styled.span`
  text-align: right;
  padding-left: 2rem;
`;

const SumLabel = styled.div`
  grid-column: span 2;

  span {
    font-weight: 700;
    display: block;
    margin-bottom: 0.25rem;
  }

  small {
    display: block;
    font-weight: 0.875rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const SumValue = styled.span`
  font-weight: 700;
  text-align: right;
  align-self: flex-start;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-column: span 3;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  font-weight: 700;
  padding: 1rem;
`;

const DownloadBtn = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;


  span {
    display: block;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    padding: 1rem;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.primary};
    transition: all 0.5s ease;
  }

  &:hover {
    text-decoration: none;

    span {
      color: ${({theme}) => theme.colors.primary};
      background-color: transparent;
    }

  }
`;

const DownloadIcon = styled(downloadSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-bottom: 0.5rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

const MailIcon = styled(mailSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-bottom: 0.5rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

 const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${DownloadBtn} {
    margin: 1rem 0rem;

    span {
      width: 100%;
    }
  }

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    ${DownloadBtn} {
      margin: 0 1rem;
    }
  }
 `;

function Legend({item}) {
  return (
    <Wrapper>
    <ContentSpacer />
    <Title>Legenda místností</Title>
      <InfoGrid>
        {
          item.rooms.map(it => (
            <React.Fragment key={it.id}>
              <Number>{`${item.id}0${it.id}`}</Number>
              <span>{it.label}</span>
              <Value>{new Intl.NumberFormat('cs-CZ', { minimumFractionDigits: 2 }).format(it.value)}&nbsp;m<sup>2</sup></Value>
            </React.Fragment>
          ))
        }
        {
          item.cellar > 0 && (
            <>
              <Number></Number>
              <span>sklep</span>
              <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(item.cellar)}&nbsp;m<sup>2</sup></Value>
            </>
          )
        }
        {/* {
          item.cellar && (
            <>
              <Number></Number>
              <span>sklep</span>
              <Value>{new Intl.NumberFormat('cs-CZ').format(item.cellar)}&nbsp;m<sup>2</sup></Value>
            </>
          )
        } */}
        <Divider />
        <SumLabel>
          <span>Podlahová plocha *</span>
          <small>* celková podlahová plocha dle vyhlášky č.&nbsp;366/2013 (bez balkonu)</small>
          {/* <small>** možnost odpočtu ceny dveří a podlah</small> */}
        </SumLabel>
        <SumValue>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(item.sum_area)}&nbsp;m<sup>2</sup></SumValue>
        <Spacer />
        {
          item.balcony && (
            <>
              <Number></Number>
              <span>balkon</span>
              <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(item.balcony)}&nbsp;m<sup>2</sup></Value>
            </>
          )
        }
        {
          item.terrace && (
            <>
              <Number></Number>
              <span>terasa</span>
              <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(item.terrace)}&nbsp;m<sup>2</sup></Value>
            </>
          )
        }
        {
          item.garden && (
            <>
              <Number></Number>
              <span>zahrada</span>
              <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(item.garden)}&nbsp;m<sup>2</sup></Value>
            </>
          )
        }
        <Spacer />
        <SecondTitle>Volitelné příslušenství</SecondTitle>
        {/* <Number></Number>
        <span>sklep</span> */}
        {/* <Value></Value> */}
        <Number></Number>
        <span>parkovací stání</span>
        <Value></Value>

        {
          item.state !== 'reserved' && item.state !== 'sold' && (
            <>
              <Spacer />
              <PriceWrapper>
                <span>Cena bytu</span>
                <span>{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(item.price)}</span>
              </PriceWrapper>
            </>
          )
        }
      </InfoGrid>

      {/* {
        item.state === 'available' && ( */}
          <>
            <Spacer />
            <Spacer />

            <BtnsWrapper>
              <DownloadBtn href={`/data/pdf/${item.id.toLocaleLowerCase()}.pdf`} title="Stáhnout kartu v PDF" target="_blank" rel="noreferrer">
                <DownloadIcon />
                <span>Stáhnout kartu</span>
              </DownloadBtn>
              <DownloadBtn
                as="span"
                onClick={() => {
                  scroller.scrollTo('contact-form-block', {
                    duration: 250,
                    delay: 0,
                    smooth: 'linear',
                    offset: -30,
                  })
                }}>
                <MailIcon />
                <span>Mám zájem</span>
              </DownloadBtn>
            </BtnsWrapper>
          </>
        {/* )
      } */}
    </Wrapper>
  );
}

export default Legend;