import React, { useState } from 'react';
import styled from 'styled-components';
import data from '../../../data.json';
import plan1Src from '../../images/plan-1np-t.jpg';
import plan2Src from '../../images/plan-2np-t.jpg';
import plan3Src from '../../images/plan-3np-t.jpg';
import plan4Src from '../../images/plan-4np-t.jpg';
import compassSrc from '../../images/img-compass.png';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.primary};
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  color: ${({theme}) => theme.colors.white};

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 992px) {
    /* position: absolute; */
    /* top: -30px; */
    margin-top: -30px;
    /* bottom: 0; */
    width: 45%;
    padding: 40px;
  }

  @media(min-width: 1200px) {
    /* top: -100px; */
    margin-top: -100px;
    padding: 60px 40px;
    /* width: 50%; */
    max-width: 660px;
  }

  @media(min-width: 1600px) {
    width: 50%;
  }
`;

const Title = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  /* margin-bottom: 3rem; */
  margin-bottom: 0;
  text-align: center;

  @media(min-width: 1200px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`;

const SubText = styled.p`
  font-size: 1.125rem;
  /* margin-bottom: 3.5rem; */
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;

  span {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  @media(min-width: 768px) {
    margin-top: 3rem;
  }
`;

const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center ;
`;

const SelectIcon = styled.div`
  width: 22px;
  height: 22px;
  border: 2px solid ${({theme}) => theme.colors.white};
  /* margin-right: 16px; */
  transition: all 0.5s ease;
  margin-bottom: 8px;

  @media(min-width: 576px) {
    margin-right: 16px;
    margin-bottom: 0;
  }

  @media(min-width: 768px) {
    width: 30px;
    height: 30px;
  }

  @media(min-width: 992px) {
    margin-bottom: 8px;
    margin-right: 0;
  }

  @media(min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 8px;
  }

  @media(min-width: 1400px) {
    margin-right: 16px;
  }
`;

const FloorItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${SelectIcon} {
    background-color: ${({theme, selected}) => selected ? theme.colors.white : 'transparent'};
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    ${SelectIcon} {
      background-color: ${({theme}) => theme.colors.white};
    }
  }

  @media(min-width: 576px) {
    flex-direction: row;
  }

  @media(min-width: 768px) {

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  @media(min-width: 992px) {
    flex-direction: column;
  }

  @media(min-width: 1200px) {
    flex-direction: row;
  }
`;

const Path = styled.path`
  fill: ${({fillColor}) => fillColor};
  opacity: 0.6;
`;

const Picker = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    /* z-index: 2; */
  }

  ${Path} {
    /* opacity: 0; */
    /* fill: ${({theme}) => theme.colors.primary}; */
    cursor: pointer;
    transition: all .5s ease;

    &.selected {
      fill: ${({theme}) => theme.colors.primary};
      opacity: 0.6;
    }
  }

  ${Path}:hover {
    opacity: 0.35;
    fill: ${({theme}) => theme.colors.primary};

    &.selected {
      opacity: 0.6;
    }
  }
`;

const CompassWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -1rem;
  z-index: 1;

  img {
    width: 70px;
    height: auto;
  }

  @media(min-width: 576px) {
    margin-top: -1rem;
  }

  @media(min-width: 768px) {
    padding-bottom: 1rem;

    img {
      width: 100px;
    }
  }

  @media(min-width: 992px) {
    padding-bottom: 0;
  }

  @media(min-width: 1200px) {
    padding-bottom: 1rem;
  }

  @media(min-width: 1400px) {
    margin-top: -2rem;
  }
`;


function SelectionPanel({selectedFloor, selectedItem, onFloorSelect, onItemSelect}) {

  const getFillColor = state => {

    if(state === 'sold') {
      return '#999999';
    }

    if(state === 'reserved') {
      return '#f3d297';
    }

    return 'transparent';
  }

  return (
    <Wrapper>
      <Title>Nabídka bytů</Title>
      {/* <SubText>
        <span>Byty 1+kk, 2+kk i&nbsp;3+kk</span>
        <br />se zahrádkami, balkony či terasami
      </SubText> */}
      <SubText>
        - Vybrané podlaží -
      </SubText>

      <SelectorWrapper>
        <FloorItem selected={selectedFloor === 1} onClick={() => onFloorSelect(1)}>
          <SelectIcon />
          <span>1.&nbsp;NP</span>
        </FloorItem>
        <FloorItem selected={selectedFloor === 2} onClick={() => onFloorSelect(2)}>
          <SelectIcon />
          <span>2.&nbsp;NP</span>
        </FloorItem>
        <FloorItem selected={selectedFloor === 3} onClick={() => onFloorSelect(3)}>
          <SelectIcon />
          <span>3.&nbsp;NP</span>
        </FloorItem>
        <FloorItem selected={selectedFloor === 4} onClick={() => onFloorSelect(4)}>
          <SelectIcon />
          <span>4.&nbsp;NP</span>
        </FloorItem>
      </SelectorWrapper>
      <SubText>
        - Vyberte si byt -
      </SubText>

      {
        selectedFloor === 1 && (
          <Picker>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1910 1588">
              <image href={plan1Src} alt="Foto - výběr bytu"></image>
                {
                  data.filter(item => item.floor === selectedFloor).map(item => (
                    <Path key={item.id} d={item.path} onClick={() => onItemSelect(item) } className={selectedItem.id === item.id ? 'selected' : ''}  fillColor={getFillColor(item.state)} />
                  ))
                }
            </svg>
          </Picker>
        )
      }

    {
        selectedFloor === 2 && (
          <Picker>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1238 1078">
              <image href={plan2Src} alt="Foto - výběr bytu"></image>
              {
                  data.filter(item => item.floor === selectedFloor).map(item => (
                    <Path key={item.id} d={item.path} onClick={() => onItemSelect(item) } className={selectedItem.id === item.id ? 'selected' : ''}  fillColor={getFillColor(item.state)} />
                  ))
                }
            </svg>
          </Picker>
        )
      }

      {
        selectedFloor === 3 && (
          <Picker>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1751 1545">
              <image href={plan3Src} alt="Foto - výběr bytu"></image>
              {
                  data.filter(item => item.floor === selectedFloor).map(item => (
                    <Path key={item.id} d={item.path} onClick={() => onItemSelect(item) } className={selectedItem.id === item.id ? 'selected' : ''}  fillColor={getFillColor(item.state)} />
                  ))
                }
            </svg>
          </Picker>
        )
      }

      {
        selectedFloor === 4 && (
          <Picker>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1879 1445.05">
              <image href={plan4Src} alt="Foto - výběr bytu"></image>
              {
                  data.filter(item => item.floor === selectedFloor).map(item => (
                    <Path key={item.id} d={item.path} onClick={() => onItemSelect(item) } className={selectedItem.id === item.id ? 'selected' : ''}  fillColor={getFillColor(item.state)} />
                  ))
                }
            </svg>
          </Picker>
        )
      }

      <CompassWrapper>
        <img src={compassSrc} alt="Ikona" />
      </CompassWrapper>
    </Wrapper>
  );
}

export default SelectionPanel;