import React from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import data from '../../../data.json';
import downloadSrc from '../../images/svg/download.svg';

const DownloadBtn = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;


  span {
    display: block;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    padding: 1rem;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.primary};
    transition: all 0.5s ease;
    margin-left: 1rem;
  }

  &:hover {
    text-decoration: none;

    span {
      color: ${({theme}) => theme.colors.primary};
      background-color: transparent;
    }

  }
`;

const DownloadIcon = styled(downloadSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-bottom: 0.5rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

const Note = styled.p`
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 0rem;

`;

const Td = styled.td`
  padding: 0.5rem 0.25rem;
  text-align: center;

  &.td-right {
    text-align: right;
  }

  @media (min-width: 1200px) {
      padding: 0.5rem;
    }

  @media (min-width: 1400px) {
    padding: 1rem;
  }
`;

const Tr = styled.tr`
  /* background-color: ${({selected}) => selected ? 'rgba(229,26,7, 0.25)' : 'inherit'} !important; */

  ${({selected, fillColor}) => !selected && !fillColor && `
    &:nth-child(even) {
      background-color: #f8f8f8;
    }

    &:nth-child(odd) {
      background-color: #fff;
    }
  `};

  ${({selected}) => selected && `
    background-color: #f3afbf;
  `};

  ${({fillColor}) => fillColor && `
    background-color: ${fillColor};
  `};
`;

const Table = styled.table`
  width: 100%;
  color: #303030;
  font-size: 0.875rem;

  .sm,
  .md {
    display: none;
  }

  thead tr th {
    /* text-transform: uppercase;
    text-align: center; */
    background-color: #fff;
    color: #545454;
    padding: 0.5rem 0.25rem;
    /* word-break: break-word; */
    font-size: 0.875rem;
    font-weight: 400;
    border-bottom: 2px solid #e51a4b;
    text-align: center;

    @media (min-width: 1200px) {
      font-size: 1rem;
      padding: 0.5rem;
    }

    @media (min-width: 1400px) {
      padding: 1rem;
      font-weight: 500;
    }
  }

  /* tbody ${Tr}:nth-child(even) {
    background-color: #f8f8f8;
  }

  tbody ${Tr}:nth-child(odd) {
    background-color: #fff;
  } */

  tbody tr:hover {
    cursor: pointer;
    background-color: #f8c6d2;
  }

  @media (min-width: 576px) {
    .sm {
      display: table-cell;
    }
  }

  @media (min-width: 768px) {
    .md {
      display: table-cell;
    }
  }

  @media (min-width: 992px) {
    font-size: 0.875rem;

    .md {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    .md {
      display: table-cell;
    }
  }

  @media (min-width: 1400px) {
    font-size: 1rem;
  }

`;

function ApartmentList({ selectedFloor, selectedItem, onItemSelect }) {

  const getStatus = state => {
    if(state === 'available') {
      return 'volný';
    }
    if(state === 'reserved') {
      return 'rezervace';
    }
    if(state === 'sold') {
      return 'prodáno';
    }
    return '-';
  }

  const getColumnLabel = () => {
    if(selectedFloor === 1) {
      return 'zahrádka'
    }

    if(selectedFloor === 4) {
      return 'terasa';
    }

    return 'balkon';
  }

  const getFillColor = state => {

    if(state === 'sold') {
      return '#cfcfcf';
    }

    if(state === 'reserved') {
      return '#ffebc8';
    }

    return null;
  }


  return (
    <>
      <Table>
        <thead>
            <tr>
              <th>byt</th>
              <th className="md">podlaží</th>
              <th>dispozice</th>
              <th>{getColumnLabel()}</th>
              <th>plocha&nbsp;*</th>
              <th className="sm">stav</th>
              <th>cena</th>
              {/* <th>pdf</th> */}
            </tr>
          </thead>
          <tbody>
          { data.filter(item => item.floor === selectedFloor).map(d => {
            return (
              <Tr
                key={d.id}
                selected={selectedItem.id === d.id}
                fillColor={getFillColor(d.state)}
                onClick={() => {
                  scroller.scrollTo('detail-block', {
                    duration: 250,
                    delay: 0,
                    smooth: 'linear',
                    offset: 0,
                  })
                  onItemSelect(d);
                }}
                >
                <Td>{d.id}</Td>
                <Td className="md">{d.floor}.&nbsp;NP</Td>
                <Td>{d.type}</Td>
                <Td className="td-right">{(d.balcony || d.terrace || d.garden) ? <span>{new Intl.NumberFormat('cs-CZ', {minimumFractionDigits: 2}).format(d.balcony || d.terrace || d.garden)}&nbsp;m<sup>2</sup></span> : '-'}</Td>
                <Td className="td-right">{new Intl.NumberFormat('cs-CZ', {minimumFractionDigits: 2}).format(d.sum_area)}&nbsp;m<sup>2</sup></Td>
                <Td className="sm">{getStatus(d.state)}</Td>
                <Td className="td-right">{d.state === 'reserved' || d.state === 'sold' ? '---' : new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(d.price)}</Td>
                {/* <Td className="td-right">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(d.price)}</Td> */}
                {/* <Td>
                  {
                    d.state === 'reserved' ? '' : (
                      <a href={`/data/pdf/${d.id.toLocaleLowerCase()}.pdf`} target="_blank" rel="noreferrer">
                        <DownloadIcon />
                      </a>
                    )
                  }

                </Td> */}
              </Tr>
            )})
            }
          </tbody>
      </Table>
      <Note>* celková podlahová plocha dle vyhlášky č.&nbsp;366/2013 (bez balkonu)</Note>

      <DownloadBtn href={`/data/standardy-bytu.pdf`} title="Stáhnout standardy bytů v PDF" target="_blank" rel="noreferrer">
        <DownloadIcon />
        <span>Standardy bytů</span>
      </DownloadBtn>
    </>
  );
}

export default ApartmentList;