import React from 'react';
import styled from 'styled-components';
import arrowSvg from '../../images/svg/arrow-right.svg';

const ArrowIcon = styled(arrowSvg)`
  height: 30px;
  width: auto;

  @media (min-width: 1400px) {
    height: 40px;
  }
`;

const Wrapper = styled.a`
  position: fixed;
  bottom: 130px;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px 16px;
  background-color: ${({theme}) => theme.colors.primary};
  text-decoration: none;
  transition: 0.5s all ease;

  &:hover {
    opacity: 0.9;
  }

  & > div {
    font-family: 'Oswald', Georgia, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.white};

    span {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    span:first-child {
      font-weight: 700;
    }
  }

  @media (min-width: 768px) {
    position: fixed;
    bottom: auto;
    top: 20%;

    & > div {
      span {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

  }

  @media (min-width: 1400px) {
    gap: 40px;
    padding: 30px 30px;

    & > div {
      span {
        font-size: 1.75rem;
        line-height: 1.875rem;
      }
    }
  }
`;

function AtelierOffer() {
  return (
    <Wrapper href={`/data/pudorys_1PP-062024.pdf`} title="Zobrazit v PDF" target="_blank" rel="noreferrer">
      <div>
        <span>Nově v nabídce:</span>
        <span>Ateliéry</span>
      </div>
      <ArrowIcon />
    </Wrapper>
  );
}

export default AtelierOffer;