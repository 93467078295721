import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { ContentWrapper } from '../common';
import { Section as DefaultSection } from '../section';
import Legend from './legend';

const Section = styled(DefaultSection)`
  background-color: #fff1ea;

  @media(min-width: 992px) {
    padding: 5.625rem 0 5.625rem 0;
  }
`;

const Heading = styled.div`
  font-size: 1.875rem;
  font-weight: 700;

  @media(min-width: 992px) {
    text-align: right;
  }

  @media(min-width: 1200px) {
    font-size: 2.25rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  margin-top: 0.5rem;

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Title = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
  color: ${({theme}) => theme.colors.primary};

  @media(min-width: 1200px) {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 3rem;
    /* margin-bottom: 5rem; */
  }
`;

const HousePlan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    width: 100%;
    height: auto;
    max-width: 600px;
    /* background-color: ${({theme}) => theme.colors.white}; */
    padding: 1.5rem;
  }

  @media(min-width: 992px) {
    order: 2;
    align-items: flex-end;
  }
`;

function ApartmentDetail({item}) {
  return (
    <Element name="detail-block">
      <Section>
        <ContentWrapper>
          <Heading as="h3">
            Byt {item.id}
          </Heading>
          <Grid>
            <Legend item={item} />
            <HousePlan>
              <Title>Půdorys</Title>
              <img src={`/data/plan/${item.id}.png`} alt={`Obrázek - půdorys byt ${item.id}`} />
            </HousePlan>
          </Grid>
        </ContentWrapper>
      </Section>
    </Element>
  );
}

export default ApartmentDetail;